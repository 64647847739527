.deleteBtn {
    margin-top: 0 !important;
}

hr {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
}

.logoutbtn {
    flex-direction: row-reverse;
}

.form-control:focus {
    border-color: rgb(233, 142, 142);
    border-width: 2px;
    /* Change this to your desired color */
    box-shadow: 0 0 10px rgba(229, 80, 80, 0.5);
    /* Optional: Add a box shadow on focus */
}

.smalltextsize {
    font-size: x-small;
    align-self: center;
    padding-left: 0.5rem;
}

.httpscolor {
    color: #0b3dca;
}

.expireOnAlign {
    align-self: center;
    color: gray;
}

.currentEmail {
    display: flex;
    flex-direction: row-reverse;
    padding: 0.2rem 0.5rem;
    color: gray;
    font-size: small;
    align-self: center;
    margin-bottom: 0;
    background: #e5e5e5;
    border-radius: 0.4rem;
    margin-right: 1rem;
}

.iamtxt {
    font-size: xx-small;
    margin-left: 0.4rem;
    padding: 0 0.2rem;
    align-self: center;
    background: whitesmoke;
    border-radius: 0.2rem;
}

.verifygovlogo {
    align-self: center;
    margin-right: 1rem;
}

.uinfogp {
    margin-left: 1rem;
}

.containerheight {
    height: 80%;
}

.myaccount {
    border-radius: 1rem !important;
}

.myaccountbtn {
    display: flex;
    flex-direction: row-reverse;
    color: gray;
    font-size: small;
    align-self: center;
    margin-bottom: 0;
    padding: 0 !important;
    background: #e5e5e5;
    border-radius: 100%;
    margin-right: 1rem;
}

button.myaccount.btn.rounded-circle {
    padding: 0 !important;
}

.customLogoutBtn {
    color: red;
    border-color: red;
}

.customLogoutBtn:hover {
    color: #fff;
    background-color: red;
    border-color: red;
}

.form-check-input {
    color: red;
    border-color: red;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes appear {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.AiBtn:hover .pistar-icon {
    color: white !important;
}

/* .smartIcon {
    font-size: 0;
    opacity: 0;
    transform: translateY(20px);
    transition: all 20s ease-out;
}

.smartIcon.appear {
    font-size: 2rem;
    opacity: 1;
    transform: translateY(0);
} */

.profileimg {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin: 0 auto;
    display: block;
}
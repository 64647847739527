body {
    font-size: .875rem !important;
    background: #F7F8FC !important;
}

.logincontainer {
    height: 100vh;
}

.imgcontainer {
    height: 100vh;
}

#loginForm {
    background: #fff;
    border-radius: 8px;
}

#loginForm .form-control {
    font-size: 0.875rem;
    height: 42px;
    border-radius: 8px;
}

.btn.loginBtn {
    border-radius: 4px;
    margin-top: 20px;
}

.imgbg {
    background-color: #004aad;
}

.formwidth {
    width: 25rem;
    display: block !important;
}

.loginrow {
    --bs-gutter-x: 0 !important;
}

.godotnuglogo {
    align-items: center !important;
}

.statusbadge {
    align-self: center;
}

.signinwithpasswordless {
    margin-top: 0 !important;

}

.recommended-browser {
    border: 1px solid #edeef0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 8px;
    width: 357px;
    background-color: white;
}

.edu-btn {
    background: #4A59A7;
    color: #E9E9E9;
    border: 2px solid #2F3C87;
}

.eduimgbg {
    background: linear-gradient(284deg, #6c7dd5, #4251a5 50%, #2F3C87);
}
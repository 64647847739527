label {
    display: inline-flex;
}

button.copy-button {
    background: transparent;
    border: none;
}

.datacontainer {
    margin: 0 !important;
    max-width: 100%;
    overflow-x: auto;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}

.navbar {
    align-self: center;
    margin-left: 1rem;
}

.slug {
    font-size: large;
    font-weight: bold;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.btn-group-sm>.btn,
.btn-sm {
    font-size: 0.6rem;
}

.createdLinks {
    display: flex;
    flex-direction: row-reverse;
    padding: 0.2rem 0.5rem;
    color: gray;
    font-size: small;
    align-self: center;
    margin-bottom: 0;
    background: #e5e5e5;
    border-radius: 0.4rem;
    margin-right: 1rem;
}

.editingHistoryList {
    padding: 0.2rem 0.5rem;
    color: rgb(80, 80, 80);
    font-size: small;
    align-self: center;
    margin-bottom: 0;
    background: #fafafa;
    border-radius: 0.4rem;
    margin-right: 1rem;
}

.editingHistoryTimestamp {
    display: flex;
    flex-direction: row-reverse;
    padding: 0.2rem 0.5rem;
    color: gray;
    align-self: center;
    margin-bottom: 0;
    background: #ffffff;
    border-radius: 0.4rem;
}

.currentEditingHistoryActivity {
    font-size: large;
    font-weight: bold;
}

.editHistoryDetail {
    margin-bottom: 0 !important;
}

.offCanvasBtn {
    width: 40%;
}

input.searchBar {
    width: 20rem;
    border-color: transparent;
    display: flex;
    flex-direction: row-reverse;
    padding: 0.2rem 0.5rem;
    font-size: small;
    align-self: center;
    margin-bottom: 0;
    background: #e5e5e5;
    border-radius: 0.4rem;
}

.customViewBtnBg {
    padding: 0.2rem 0.5rem;
    color: rgba(93, 1, 1, 0.734);
    font-size: small;
    align-self: center;
    margin-bottom: 0;
    background: #ffdede;
    border-radius: 0.4rem;
}

.modalBody {
    margin: 0;
}

.pagination .active a {
    background-color: #ff003c;
    border-color: #ff0000;
    border-radius: 0.25rem;
    /* margin: 0 0.5rem;
    padding: 0.2rem 0.5rem; */
    color: #ffffff;
}

.pagination .disabled a {
    background-color: #f8f9fa;
    border-color: #f8f9fa;
    border-radius: 0.25rem;
    color: #6c757d;
}

.pagination li a {
    padding: 0.2rem 0.5rem;
    text-decoration: none;
    color: #ff0000;
    font-size: large;
}

.navbarbg {
    background-color: rgba(37, 38, 40, .21);
    background-image: radial-gradient(circle at 25%, hsla(0, 0%, 100%, .2), rgba(50, 50, 50, .2) 80%);
    -webkit-backdrop-filter: blur(14px);
    backdrop-filter: blur(14px);
}

.qrDownloadBtn {
    margin-left: -0.6rem;
    color: black;
    border-color: black;
    background-color: #e5e5e5;
}

.qrDownloadBtn:hover {
    background-color: black;
    border-color: black;
    color: white;
}
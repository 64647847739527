.text-Box {
    flex-direction: column;
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
    margin-top: -10vh;
}

.mheight {
    height: 100vh;
}

.copylinkbtn {
    margin-top: 1rem;
}
.footerMax {
    width: 100%;
}

@media print {
    @page {
        size: landscape;
    }
}

.my-masonry-grid {
    display: flex;
    margin-left: -30px; /* adjust this if you need to */
    width: auto;
}
.my-masonry-grid_column {
    padding-left: 30px; /* same as the negative margin on .my-masonry-grid */
    background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
    margin-bottom: 30px;
}

span.text-muted.shortlink {
    align-self: center;
}
body {
    font-size: .875rem !important;
    background: #F7F8FC !important;
}

.acccreatecontainer {
    height: 80vh;
}

.imgcontainer {
    height: 100vh;
}

#loginForm {
    background: #fff;
    border-radius: 8px;
}

#loginForm .form-control {
    font-size: 0.875rem;
    height: 42px;
    border-radius: 8px;
}

.btn.loginBtn {
    border-radius: 4px;
    margin-top: 20px;
}

.imgbg {
    /* background-color: #004aad; */
    background:linear-gradient(284deg,rgb(254,205,165) 0%,rgb(254,45,45) 50%,rgb(107,0,62) 100%)
}

.formwidth {
    width: 25rem;
    display: block !important;
}

.loginrow {
    --bs-gutter-x: 0 !important;
}

.godotnuglogo {
    align-items: center !important;
}

.header404 {
    min-height: 94vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    padding: 0 4rem;
}

.App404 {
    text-align: center;
}